import { uploadImage } from './config';


const generateFileName = () => {
    return `${uuidv4()}-${Date.now()}.webp`;
};

const uuidv4 = () => {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
        const r = (Math.random() * 16) | 0,
            v = c === 'x' ? r : (r & 0x3) | 0x8;
        return v.toString(16);
    });
};

export const uploadToS3 = async (blob, s3pathName, randomValue, fileName = "") => {
    try {
        // const fileName = generateFileName();
        const formData = new FormData();
        formData.append("image", blob, fileName == "" ? randomValue + blob.name : fileName);
        const data = await uploadImage(formData, s3pathName);
        console.log("blob................", blob)
        console.log("blob.name................", blob.name)
        console.log("formData................", formData)

        console.error('Upload Success:', data);


        return data.data.imageUrl;
    } catch (error) {
        console.error('Upload failed:', error);
        throw error;
    }
};
